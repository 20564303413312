import React, { useState } from 'react';
import { Progress, Navbar, NavbarBrand, Row, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { navigateToCareer, navigateToHome } from "../utils/navigation";

export default function InitProgressBar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="master">
      <div className="shadow-lg rounded-lg bg-card text-white">
        <Navbar expand="md">
          <NavbarBrand >
            <Row>
              <img src="/assets/logo2.png" width="160px" height="40px" alt="logo" />
            </Row>
          </NavbarBrand>
          <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} >
            <span color="white" className="fa fa-bars" ></span>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem className="ml-3 cursor" onClick={() => navigateToHome()}>
                <NavLink>Home</NavLink>
              </NavItem>
              <NavItem className="ml-3 cursor" onClick={navigateToCareer}>
                <NavLink>Careers</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        <div className="d-flex justify-content-between mt-5">
          <div className="d-flex flex-row">
            <div className="ml-5">
              <br /><br />
              <h1>Loading......</h1> <Progress animated value={50} />
              <div className="mt-3 mb-5">
                <p className="lead">  We are preparing for the interview content page, please wait for a moment. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
