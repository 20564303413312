import React from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface DeleteButtonProps {
  toggle: (e: React.MouseEvent) => void;
  isOpen: boolean;
  deleteHandler: (e: React.MouseEvent) => void;
}

export function DeleteButton({ toggle, isOpen, deleteHandler }: DeleteButtonProps) {
  return (
    <Col xs="3">
      <Button className="float-right" color="danger" variant="" size="sm" onClick={toggle}>Delete</Button>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
        <ModalBody>
          Are you sure to DELETE?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteHandler}>JUST DO IT</Button>{' '}
          <Button color="secondary" onClick={toggle}>NO NO NO</Button>
        </ModalFooter>
      </Modal>
    </Col>);
}
