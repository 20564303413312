import React from "react";
import { commit } from "../config";
import { useIsAuthenticated } from "@azure/msal-react";

export function Copyright() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      <p className="text-muted credit">
        {
          (commit !== "DEV" && commit !== "Unknown" && isAuthenticated) ?
            <a href={`https://github.com/Outlook-Suzhou/1board.net-app/commit/${commit}`}>{commit.substring(0, 6)}</a>
            :
            commit.substring(0, 6)
        } © 2020 - {new Date().getFullYear()} <a href="https://github.com/Outlook-Suzhou/1boardFeedbacks/issues">Microsoft, Outlook Suzhou</a>
      </p>
    </>
  )
}
