import 'codemirror/lib/codemirror.css';

import React from 'react';
import { Badge } from 'reactstrap';
import CodeMirror from "react-codemirror";
import CodeviewHeaderMenu, { Page } from "./HeaderMenuComponent";
import { defaultMode, defaultTheme } from '../utils/codemirror';

interface ReadonlyModeViewProps {
  interviewer: boolean;
  name: string;
  interviewers:string[];
  candidates:string[];
  code: string;
  history: any;
}

export default function ReadonlyModeView({ interviewer, name,interviewers, candidates, code, history }: ReadonlyModeViewProps) {
  return (
    <div className="one-code-container">
      <div className="shadow-lg text-white code-card">
        <CodeviewHeaderMenu
          isInterviewer={interviewer}
          current={Page.ReadOnly}
          participants={name}
          interviewers={interviewers}
          candidates={candidates}
          history={history} />

        <div className="row">
          <Badge color="primary" pill>Read only</Badge>
        </div>

        <div className="codearea">
          <CodeMirror value={code} options={{
            lineNumbers: true,
            readOnly: true,
            mode: defaultMode,
            theme: localStorage.getItem("editor#theme") || defaultTheme,
            // placeholder: "Please type your code here...",
            value: code,
            autofocus: false,
          }}
          autoFocus={false} />
        </div>

      </div>
    </div>
  )
}
