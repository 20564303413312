import './DropdownComponents.scss'
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Row } from 'reactstrap';

import { themes } from "../config/themes";
import { languages } from "../config/languages";
import * as cm from '../utils/codemirror';

interface DropDownProps {
  defaultLanguage: string;
  showLanguageDropdown: boolean;
  defaultTheme?: string;
  onLanguageChange: (arg0: string) => void;
  onThemeChange: (arg0: string) => void;
  onToggleFullScreen: (e: React.MouseEvent) => void;
}

export default function ({
  defaultLanguage,
  defaultTheme,
  onLanguageChange,
  onThemeChange,
  onToggleFullScreen,
  showLanguageDropdown,
}: DropDownProps) {
  const [languageOpen, setLanguageOpen] = useState(false);
  const [themeOpen, setThemeOpen] = useState(false);
  const [languageValue, setLanguageValue] = useState(defaultLanguage);
  const [themeValue, setThemeValue] = useState(defaultTheme);

  const toggleTheme = (event: React.KeyboardEvent) => {
    event.preventDefault();
    setThemeOpen(!themeOpen);
  }

  const toggleLanguage = (event: React.KeyboardEvent) => {
    event.preventDefault();
    setLanguageOpen(!languageOpen);
  }

  // 父控件(CodeView)修改了语言或者收到修改语言的信息后，触发子控件修改
  useEffect(() => {
    let languageValue = cm.defaultMode;
    try {
      languageValue = languages.filter(language =>
        language.id === defaultLanguage
      )[0].name;
    } catch (error) {
      console.info("not found language", defaultLanguage);
    }
    setLanguageValue(languageValue);
  }, [defaultLanguage]);

  useEffect(() => {
    let themeValue = cm.defaultTheme;
    try {
      themeValue = themes.filter(theme =>
        theme.id === defaultTheme
      )[0].name;
    } catch (error) {
      console.info("not found theme", defaultTheme);
    }
    setThemeValue(themeValue);
  }, [defaultTheme]);

  const Language_changeValue = (e: React.MouseEvent) => {
    const id = e.currentTarget.getAttribute("id");
    const text = e.currentTarget.getAttribute("text");

    text && setLanguageValue(text);
    id && onLanguageChange(id);
  }

  const Theme_changeValue = (e: React.MouseEvent) => {
    const id = e.currentTarget.getAttribute("id");
    const text = e.currentTarget.getAttribute("text");

    text && setThemeValue(text);
    id && onThemeChange(id);
  }

  return (
    <div className="row col-12">
      {
        showLanguageDropdown ?
          <div className="col-md-3 col-sm-6 col-12" >
            <Row>
              <Label>Language:</Label>
              <Dropdown isOpen={languageOpen} toggle={toggleLanguage}>
                <DropdownToggle className="cursor" tag="span" data-toggle="dropdown" caret>
                  {languageValue}
                </DropdownToggle>
                <DropdownMenu>
                  {
                    languages.map(e => {
                      return <DropdownItem id={e.id} onClick={Language_changeValue} key={e.id} >
                        {e.name}
                      </DropdownItem>
                    })
                  }
                </DropdownMenu>
              </Dropdown>
            </Row>
          </div>
          :
          <></>
      }

      <div className=" col-md-3 col-sm-6 col-12 " >
        <Row>
          <Label>Theme:</Label>
          <Dropdown isOpen={themeOpen} toggle={toggleTheme}>
            <DropdownToggle className="cursor" tag="span" data-toggle="dropdown" caret>
              {themeValue}
            </DropdownToggle>
            <DropdownMenu>
              {themes.map(e =>
                <DropdownItem
                  id={e.id}
                  onClick={Theme_changeValue}
                  key={e.id}>
                  {e.name}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </Row>
      </div>

      {
        showLanguageDropdown ?
          <div className="fullscreen-button col-sm-12 col-md-6 pull-right">
            <span className="cursor" onClick={onToggleFullScreen}><span className="fa fa-expand"></span><span>&nbsp;Full Screen</span></span>
          </div>
          :
          <div className="fullscreen-button col-sm-12 col-md-9 pull-right">
            <span className="cursor" onClick={onToggleFullScreen}><span className="fa fa-expand"></span><span>&nbsp;Full Screen</span></span>
          </div>
      }
    </div>
  );
}

