import CodeMirror from "codemirror";
import Cursor from "./cursor";
import * as R from "rambda";

export function getOpIndex(op: any) {
  if(op.length==0) return;
  return op[0]['p'][1];
}

export function getOpNewMessage(op: any) {
  return op[0]['li'];
}

export function getOpAddNumber(op: any) {
  return op[0]['na'];
}
export function checkStatesunion(states: any){
  //adapt history version, make sure statesunion elements are existed
  //add states
  if(states.data.statesunion.length<=OpIndexTable.Interviewers){
    states.submitOp({
      p: ['statesunion', OpIndexTable.Interviewers],
      li: []
    });
  }
  if(states.data.statesunion.length<=OpIndexTable.Candidates){
    states.submitOp({
      p: ['statesunion', OpIndexTable.Candidates],
      li: []
    });
  }
  if(states.data.statesunion.length<=OpIndexTable.ViewOnly){
    states.submitOp({
      p: ['statesunion', OpIndexTable.ViewOnly],
      li: false
    });
  }
  if(states.data.statesunion.length<=OpIndexTable.Cursors){
    states.submitOp({
      p: ['statesunion', OpIndexTable.Cursors],
      li: []
    });
  }
  //check undefined
  if(states.data.statesunion[OpIndexTable.Interviewers]===undefined){
    states.submitOp({
      p: ['statesunion', OpIndexTable.Interviewers],
      ld: states.data.statesunion[OpIndexTable.Interviewers],
      li: []
    });
  }
  if(states.data.statesunion[OpIndexTable.Candidates]===undefined){
    states.submitOp({
      p: ['statesunion', OpIndexTable.Candidates],
      ld: states.data.statesunion[OpIndexTable.Candidates],
      li: []
    });
  }
  if(states.data.statesunion[OpIndexTable.Cursors]===undefined){
    states.submitOp({
      p: ['statesunion', OpIndexTable.Cursors],
      ld: states.data.statesunion[OpIndexTable.Cursors],
      li: []
    });
  }
}
export function addInterviewerOp(states: any, interviewer: string, cursor: Cursor) {
  if (!R.includes(interviewer, states.data.statesunion[OpIndexTable.Participation])) {
    states.submitOp({
      p: ['statesunion', OpIndexTable.Participation],
      ld: states.data.statesunion[OpIndexTable.Participation],
      li: [...states.data.statesunion[OpIndexTable.Participation], interviewer]
    });
  }
  if (!R.includes(interviewer, states.data.statesunion[OpIndexTable.Interviewers])) {
    states.submitOp({
      p: ['statesunion', OpIndexTable.Interviewers],
      ld: states.data.statesunion[OpIndexTable.Interviewers],
      li: [...states.data.statesunion[OpIndexTable.Interviewers], interviewer]
    });
    states.submitOp({
      p: ['statesunion', OpIndexTable.Cursors],
      ld: states.data.statesunion[OpIndexTable.Cursors],
      li: [...states.data.statesunion[OpIndexTable.Cursors], cursor]
    });
  }
}

export function addParticipateOp(states: any, participate: string) {
  states.submitOp({
    p: ['statesunion', OpIndexTable.Participation],
    ld: states.data.statesunion[OpIndexTable.Participation],
    li: [...states.data.statesunion[OpIndexTable.Participation], participate]
  });
}
export function addcandidateOp(states: any, candidate: string) {
  states.submitOp({
    p: ['statesunion', OpIndexTable.Candidates],
    ld: states.data.statesunion[OpIndexTable.Candidates],
    li: [...states.data.statesunion[OpIndexTable.Candidates], candidate]
  });
}
export function addCursorOp(states: any, cursor: Cursor){
  states.submitOp({
    p: ['statesunion', OpIndexTable.Cursors],
    ld: states.data.statesunion[OpIndexTable.Cursors],
    li: [...states.data.statesunion[OpIndexTable.Cursors], cursor]
  });
}
export function changeLanguageOp(states: any, language: string) {
  states.submitOp({
    p: ['statesunion', OpIndexTable.Language],
    ld: states.data.statesunion[OpIndexTable.Language],
    li: language
  },
    {});
}
export function changeCursorOp(states:any, index:number, cursor: Cursor){
  var afterCursors=states.data.statesunion[OpIndexTable.Cursors];
  afterCursors[index]=cursor;
  states.submitOp({
    p:['statesunion', OpIndexTable.Cursors],
    ld: states.data.statesunion[OpIndexTable.Cursors],
    li: afterCursors
  })
}
export function finishOp(states: any) {
  states.submitOp({
    p: ['statesunion', OpIndexTable.Completeness],
    ld: false,
    li: true
  });
}

export function setViewOnlyOp(states: any) {
  states.submitOp({
    p: ['statesunion', OpIndexTable.ViewOnly],
    ld: states.data.statesunion[OpIndexTable.ViewOnly],
    li: !states.data.statesunion[OpIndexTable.ViewOnly]
  });
}

export enum OpIndexTable {
  Completeness = 0,
  Language = 1,
  Connections = 2,
  Participation = 3,
  Interviewers = 4,
  Candidates =5,
  ViewOnly = 6,
  Cursors = 7
}
