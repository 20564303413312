import './HeaderMenuComponent.scss';
import 'codemirror/lib/codemirror.css';

import React, { useState } from 'react';
import { navigateToHome, navigateToCareer, navigateToFeedback } from "../utils/navigation";
import { Row, UncontrolledTooltip, Nav, Navbar, NavbarToggler, Collapse, NavItem, NavbarBrand, NavbarText, Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, FormGroup, Form, Label, Button, Badge, UncontrolledPopover, PopoverBody, PopoverHeader } from 'reactstrap';
import { createInterview } from '../utils/createInterview';

import { ShareModal } from "./ModalComponents";

import { pca } from "../index"
import { isProd, shortenServer } from "../config";
import axios from "axios";
import { sendEmail } from "../services/boardService";
import UserListComponent from './userListComponent';

export enum Page {
  Home, History, Interview, ReadOnly
}

interface HeaderMenuProps {
  current: Page;
  isInterviewer: boolean;
  interviewerDisplayname?: string | null;
  participants: string;
  interviewers: string[];
  candidates: string[];
  history: any;
}

export default function HeaderMenuComponent({ current, isInterviewer, interviewerDisplayname, participants,interviewers, candidates, history }: HeaderMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [short, setShort] = useState<string | undefined>(undefined);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isSendSuccess, setSendSuccess] = useState(false);
  const [isSendComplete, setSendComplete] = useState(false);

  // 跳转到History界面
  function toHistory(event: React.MouseEvent) {
    event.preventDefault();
    history.push("/history");
  }

  function copyLink() {
    if (!!short) {
      navigator.clipboard.writeText(short).then(() => {
        setCopied(true);
      });
    } else {
      console.error("[COPY] No short url found");
    }
  }

  function send(receiver: string) {
    console.log("ok, now send funtion get your adress:", receiver)
    sendEmail({ "receiver": receiver || "", "shortLink": short })
      .then((response) => {
        if (response) {
          setSendSuccess(response.data);
          setSendComplete(response.data); // true or false
          setTimeout(() => {
            setSendComplete(false);
          }, 3000);
        } else {
          console.error(response);
        }
      });
    toggleShareModal();
  }

  function toggleShareModal() {
    setCopied(false);
    setShareModalOpen(false);
  }


  // 登出
  function logout(event: React.MouseEvent) {
    event.preventDefault();
    pca.logoutRedirect();
  }

  const username = localStorage.getItem("username") || "";
  const email = localStorage.getItem("email") || "";
  const interviewer = isInterviewer ? localStorage.getItem("interviewer") : participants;

  function onNewInterviweClicked(event: React.MouseEvent) {
    event.preventDefault();

    createInterview(interviewer)
      .then((url) => {
        // TODO: fix react router component not update
        if (current === Page.ReadOnly) {
          window.location.href = url;
        } else {
          history.push(url);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  function onClear(event: React.MouseEvent) {
    event.preventDefault();
    localStorage.clear();
    console.info("localStorage data are cleared.");
  }

  async function share(event: React.MouseEvent) {
    event.preventDefault();

    let url = short;
    if (url === undefined) {
      const currentUrl = document.URL;
      const res = await axios.post(shortenServer, {
        origin: currentUrl,
      }, {
        headers: {
          "content-type": "application/json",
        }
      });
      url = `${shortenServer}/${res.data}`;
      setShort(url);
    }

    setShareModalOpen(true);
  }

  return (
    <div className="one-header-menu">
      <Navbar expand="md">
        <NavbarBrand href='/'>
          <Row className="one-header-brand">
            <img src="/assets/logo2.png" width="160px" height="40px" alt="logo" />
          </Row>
        </NavbarBrand>
        <NavbarToggler onClick={() => { setIsOpen(!isOpen) }}>
          <Nav>
          <NavItem>
            <UncontrolledDropdown>
              <DropdownToggle data-toggle='dropdown' tag='span'>
                <span style={{marginTop:"15px"}} color="white" className="fa fa-bars"></span>
              </DropdownToggle>
              <DropdownMenu>
                {
                  isInterviewer
                  && current !== Page.Home
                  && <DropdownItem className="cursor" onClick={() => navigateToHome(history)}>Home</DropdownItem>
                }
                {
                  isInterviewer
                  && current !== Page.History
                  && <DropdownItem className="cursor" onClick={toHistory}>History</DropdownItem>
                }
                {
                  isInterviewer
                  && current !== Page.Interview
                  && <DropdownItem className="cursor" onClick={onNewInterviweClicked}>New Interview</DropdownItem>
                }
                {
                  isInterviewer
                  && <DropdownItem className="cursor" onClick={navigateToFeedback}>Feedback</DropdownItem>
                }
                <DropdownItem className="cursor" onClick={navigateToCareer}>Careers</DropdownItem>
                {
                  isInterviewer
                  && <DropdownItem className="cursor" onClick={share}>Share</DropdownItem>
                }

                {
                  !isProd
                  && <DropdownItem className="cursor" onClick={onClear}>Clear cache</DropdownItem>
                }
                {
                  !!email && username &&
                  <DropdownItem style={{marginLeft:"0"}} className="fa fa-sign-out default-cursor" onClick={logout}>Log out</DropdownItem>
                }
              </DropdownMenu>
            </UncontrolledDropdown>
            </NavItem>
            {
            !email && username &&
            // <NavbarText className="ml-3 default-cursor small">{participants}{!!participants && " | "}{username}</NavbarText>
            <NavbarText className="ml-3 default-cursor small">
              <UserListComponent members={candidates} isInterviewer={false} username={username}/>
              {!!participants && " | "}
              <UserListComponent members={interviewers} isInterviewer={true} username={username}/>
            </NavbarText>
            }

            {
              !!email && username &&
              <NavbarText className="ml-3 default-cursor">
                {/* <span className="small">{participants}{!!participants && " | "}{interviewerDisplayname || username} ({email})</span> */}
                <UserListComponent members={candidates} isInterviewer={false} username={username}/>
                {!!participants && " | "}
                <UserListComponent members={interviewers} isInterviewer={true} username={username}/>

              </NavbarText>
            }
          </Nav>
        </NavbarToggler>
        <Collapse navbar>
          <Nav className="mr-auto" navbar>
            {
              isInterviewer
              && current !== Page.Home
              && <NavItem className="ml-3 cursor" onClick={() => navigateToHome(history)}>Home</NavItem>
            }

            {
              isInterviewer
              && current !== Page.History
              && <NavItem className="cursor ml-3" onClick={toHistory}>History</NavItem>
            }

            {
              isInterviewer
              && current !== Page.Interview
              && <NavItem className="cursor ml-3" onClick={onNewInterviweClicked}>New Interview</NavItem>
            }
            {
              isInterviewer
              && <NavItem className="cursor ml-3" onClick={navigateToFeedback}>Feedback</NavItem>
            }

            <NavItem className="cursor ml-3" onClick={navigateToCareer}>Careers</NavItem>

            {
              isInterviewer
              && <NavItem className="cursor ml-3" onClick={share}>Share</NavItem>
            }

            {
              !isProd
              && <NavItem className="cursor ml-3" onClick={onClear}>Clear cache</NavItem>
            }
          </Nav>


          {
            !email && username &&
            // <NavbarText className="ml-3 default-cursor small">{participants}{!!participants && " | "}{username}</NavbarText>
            <NavbarText className="ml-3 default-cursor small">
              <UserListComponent members={candidates} isInterviewer={false} username={username}/>
              {!!participants && " | "}
              <UserListComponent members={interviewers} isInterviewer={true} username={username}/>
            </NavbarText>
          }

          {
            !!email && username &&
            <NavbarText className="ml-3 default-cursor">
              {/* <span className="small">{participants}{!!participants && " | "}{interviewerDisplayname || username} ({email})</span> */}
              <UserListComponent members={candidates} isInterviewer={false} username={username}/>
              {!!participants && " | "}
              <UserListComponent members={interviewers} isInterviewer={true} username={username}/>
              <span className="fa fa-sign-out cursor" id="logouticon" onClick={logout} />
              <UncontrolledTooltip placement="top" autohide={false} target="logouticon">Logout</UncontrolledTooltip>
            </NavbarText>
          }

        </Collapse>
      </Navbar>

      <ShareModal isOpen={isShareModalOpen} toggle={toggleShareModal} copy={copyLink} send={send} copied={copied} link={short} />
      {isSendComplete && <Alert color={isSendSuccess ? "success" : "danger"} toggle={function noRefCheck() { }}>
        Send {isSendSuccess ? "Success" : "Error"} !
      </Alert>}
    </div>
  );
}
