import axios from "axios";
import { getWithAuthentication, postWithAuthentication } from "./common";

export function addParticipate(urlParam: string, participate: string) {
  const url = `/api/add_participate?urlParam=${urlParam}&participate=${participate}`;
  return axios.post(url);
}

export function checkInterviewer(urlParam: string, interviewer: string, email: string) {
  const url = `/api/check_interviewer?urlParam=${urlParam}&interviewer=${interviewer}&email=${email}`;
  return axios.get(url);
}

export function makeComplete(params: any) {
  const url = "/api/make_complete";
  return postWithAuthentication(url, params)
}

export function initBoard(urlParam: string) {
  const url = `/api/${urlParam}/init`;
  return axios.get(url);
}

export function getContent(urlParam: string) {
  const url = `/api/get_content?permParam=${urlParam}`;

  return getWithAuthentication(url);
}

export function findBoardsByInterviewer(username: string, email: string) {
  const url = `/api/find_boards_by_interviewer?interviewer=${username}&email=${email}`;

  return getWithAuthentication(url);
}

export function createBoard(interviewer: string, showName: string, email: string) {
  const url = `/api/create_board?interviewer=${interviewer}&showname=${showName}&email=${email}`;
  return postWithAuthentication(url);
}

export function deleteInterview(urlParam: string, complete: any) {
  const url = `/api/${urlParam}/delete?complete=${complete}`;

  return postWithAuthentication(url);
}

export function getToken(urlParam: string, completed: boolean = false) {
  const url = `/api/${urlParam}/get_token?completed=${completed}`;

  return getWithAuthentication(url);
}

export function sendEmail(body: object) {
  const url = `/api/send_mail`;

  return postWithAuthentication(url, body);
}
