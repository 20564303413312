import { AccountInfo } from "@azure/msal-browser";
import axios from "axios";
import { pca } from "../index"

async function acquireAccessToken(account: AccountInfo) {
  const response = await pca.acquireTokenSilent({
    scopes: ["api://3fe444ac-c031-4290-8de0-05d59a7b6fc7/oneboard_access"],
    account,
  });
  return response.accessToken;
}

export async function getWithAuthentication(endpoint: string): Promise<any> {
  const account = pca.getActiveAccount();

  if (!account) {
    console.error("No active account")
    return;
  }

  const token = await acquireAccessToken(account);
  const res = await axios.get(endpoint, {
    headers: {
      "Authorization": `Bearer ${token}`,
    },
  });

  return res;
}

export async function postWithAuthentication(endpoint: string, body: any = ""): Promise<any> {
  const account = pca.getActiveAccount();
  if (!account) {
    console.error("No active account")
    return;
  }

  const token = await acquireAccessToken(account);

  const res = await axios.post(endpoint, body, {
    headers: {
      "Authorization": `Bearer ${token}`
    },
  });

  return res;
}
