import React, { useState } from "react";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from "reactstrap";

import { navigateToCareer } from "../utils/navigation";

export default function () {
  const [isOpen, setIsOpen] = useState(false);

  const onNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="master">
      <div className="shadow-lg rounded-lg bg-card text-white">
        <Navbar expand="md">
          <NavbarBrand href="/">
            <img src="/assets/logo2.png" width="160px" height="40px" alt="logo" />
          </NavbarBrand>
          <NavbarToggler onClick={onNavbarToggle}>
            <span color="white" className="fa fa-bars" ></span>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem className="ml-3 cursor" onClick={navigateToCareer}>
                Careers
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        <div className="d-flex justify-content-between mt-5">
          <div className="d-flex flex-row">
            <div className="ml-5 user-select-none">
              <br /><br />
              <h1>Thanks for participating in Microsoft interview!</h1>
              <div className="mt-3 mb-5">
                <p className="lead">
                  We will give you feedback as soon as possible based on your performance, please be patient.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* TODO: <div className="container text-center home-footer">
          <Copyright />
        </div> */}
      </div>

    </div>
  )
}
