import React, { useRef, useState } from "react";
import { Badge, ModalHeader, Modal, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback, Container, Col, Row, UncontrolledTooltip } from "reactstrap";
import ValidationMessage from "./ValidationMessageComponent";

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
}

interface FinishModalProps extends ModalProps {
  handler: (e: React.MouseEvent) => void;
}

export function FinishModal({ isOpen, toggle, handler }: FinishModalProps) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Complete an Interview</ModalHeader>
      <ModalBody>
        Once this interview has been completed, Code-Review content will be in read-only mode and interviewee page will be closed.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={handler} >Yes</Button>
      </ModalFooter>
    </Modal>
  );
}

export function TimeoutModal({ isOpen, toggle }: any) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Timer</ModalHeader>
      <ModalBody>
        Time Out!
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ErrorModelProps extends ModalProps {
}

export function ErrorModal({ isOpen, toggle }: ErrorModelProps) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>There is something wrong...</ModalHeader>
      <ModalBody>
        Sorry, there's something wrong with our server.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>OK</Button>
      </ModalFooter>
    </Modal>
  );
}

export function PermErrorModal({ isOpen }: any) {
  return (
    <Modal keyboard={false} isOpen={isOpen}>
      <ModalHeader close={<></>} >There is something wrong...</ModalHeader>
      <ModalBody>
        Sorry, there's something wrong with our server. Please refresh this page.
      </ModalBody>
    </Modal>
  );
}

export function ParticipantPromptModal({ isParticipantModalOpen, toggleParticipantModal, participantNameConfirmed, updateUsername, user, userValid, pca, errorMsg }: any) {
  return (
    <Modal autoFocus={false} keyboard={false} isOpen={isParticipantModalOpen} toggle={toggleParticipantModal} backdrop='static'>
      <ModalHeader toggle={toggleParticipantModal} close={<></>} >Please input your name</ModalHeader>
      <ModalBody>
        <Form onSubmit={participantNameConfirmed}>
          <FormGroup>
            <Label htmlFor="participater">Please enter your name:&nbsp;&nbsp; </Label>
            <Input autoFocus={true} type="text" id="participater" name="participater" className="form-field" value={user || ""}
              onChange={(e) => updateUsername(e.target.value)} placeholder="Your name" valid={userValid} />
            {
              <ValidationMessage valid={userValid} message={errorMsg} />
            }
          </FormGroup>
          <Button className="col-lg-5  pull-left" color="secondary" onClick={() => { pca.loginRedirect() }}>Interviewer</Button>
          <Button className="col-lg-5  pull-right" type="submit" value="submit" color="primary" disabled={!userValid}>Candidate</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export function StartInterviewPropmptModal({ isOpen, toggleModal, onSubmit, username, onUsrnameChange, valid, errorMsg }: any) {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} >Start Interview</ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label htmlFor="interviewer">Interviewer:&nbsp;&nbsp; </Label>
            <Input
              type="text"
              id="interviewer"
              name="interviewer"
              className="form-field"
              defaultValue={username || ""}
              onChange={onUsrnameChange}
              placeholder={username || "Interviewer name"}
              valid={valid} />
            {
              <ValidationMessage valid={valid} message={errorMsg.interviewer || ""} />
            }
          </FormGroup>
          <Button className="col-lg-5  pull-right" type="submit" value="submit" color="primary" disabled={!valid}>Start</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export function ShareModal({ isOpen, toggle, copy, send, copied, link }: any) {
  const [message, setMessage] = useState('');
  const [isInValid, setInValid] = useState(false);
  const shortlinkRef=useRef(null);
  const handleMessageChange = (e: any) => {
    setInValid(false);
    setMessage(e.target.value);
  };

  const handleMessageSubmit = () => {
    // validate email string pattern
    let string = message.replace(/\s|&nbsp;/g, '')
    let reg = /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
    if (reg.test(string)) {
      send(message);
      setMessage("");
    } else {
      setInValid(true);
    }

  }
  const selectContents = () => {
    const selection = window.getSelection();
    if(selection && shortlinkRef.current){
        selection.selectAllChildren(shortlinkRef.current!);
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Share or Email Current Page with Url</ModalHeader>
      <ModalBody onDoubleClick={selectContents}>
      <Container>
          <Row xs="2">
            <Col className="bg-light" sm="10" xs="6">
              <Label for="exampleEmail" sm={2}>
                    Url:
              </Label>
              <span ref={shortlinkRef} style={{verticalAlign: "middle"}}>{link}</span>
              {copied ? <>&nbsp;<Badge className="badge badge-secondary">Copied</Badge></> : <></>}
            </Col>
            <Col className="bg-light" sm="2" xs="6">
              <Button color="primary" id="copy_button" onClick={copy} size="sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16">
                  <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                  <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                </svg>
              </Button>
              <UncontrolledTooltip
                placement="top"
                target="copy_button"
              >
                Copy Link!
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col className="bg-white">
               <hr />
            </Col>
          </Row>
          <Row>
            <Col className="bg-light" sm="10" xs="6">
              <Form>
                <FormGroup row>
                  <Label for="exampleEmail" sm={2}>
                    Email:
                  </Label>
                  <Col sm={9}>
                  <Input
                    id="exampleEmail"
                    name="email"
                    placeholder="Enter candidate's email address.."
                    type="email"
                    value={message}
                    onChange={handleMessageChange}
                    invalid={isInValid}
                  />
                  {isInValid && <FormFeedback>Invalid Eamil Address!</FormFeedback>}
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col className="bg-light" sm="2" xs="6">
              <Button color="primary" id="email_button" onClick={handleMessageSubmit} size="sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-envelope-plus" viewBox="0 0 16 16">
                  <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5Z" />
                </svg>
              </Button>
              <UncontrolledTooltip
                placement="top"
                target="email_button"
              >
                Send Email!
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

