import './codeviewComponent.scss'
import CodeMirror from "codemirror";
import React, { Component, createRef } from "react";
import WebSocket from "reconnecting-websocket";

import DropDowns from "./DropdownComponents";

import { getWsProtocol, getShareHost } from "../utils/hostname";

import 'codemirror/addon/display/fullscreen';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/display/fullscreen.css';

import { defaultMode, defaultTheme, loadHighlights, loadThemes } from "../utils/codemirror";
import { Badge } from "reactstrap";
import { addInterviewerOp as tryAddInterviewerOp, addParticipateOp, addcandidateOp, changeLanguageOp, finishOp, getOpAddNumber, getOpIndex, OpIndexTable, setViewOnlyOp, addCursorOp, changeCursorOp, checkStatesunion } from "../utils/sharedb";
import Cursor from "../utils/cursor";
import { colors } from "../config/colors";
import tinycolor from '@ctrl/tinycolor';
import * as R from "rambda";
const sharedb = require("sharedb/lib/client");
const StringBinding = require("sharedb-string-binding");

const ws = getWsProtocol();
let isSyncMessage=false;//doc sync flag
export interface CodeViewProps {
  isInterviewer: boolean;
  urlParam: string;
  token: string;
  interviewee?: string;
  completed: boolean,
  triggerActive: boolean,
  user?: string;
  showLanguageDropdown: boolean;
  onError: (err: string) => void;
  onUserEnter: () => void;
  onUserLeave: () => void;
  onCurrentOnlineChange: (online: number) => void;
  onPermError: (err: string) => void;
  dismissError: () => void;
  onFinish: (code: string) => void;
  onParticipate: (participants: any[]) => void;
  setIsViewOnly: (isViewOnly: boolean) => void;
  resetActiveTrigger: () => void;
  onInterviewer: (interviewers: any[]) => void;
  onCandidate: (candidates: any[]) => void;
}

interface CodeviewStates {
  code: string;
  options: any;
  isFullScreen: boolean;
}

class CodeView extends Component<CodeViewProps, CodeviewStates> {
  private editor: CodeMirror.Editor | undefined;
  private textarea: React.RefObject<HTMLTextAreaElement>;
  private globalStates: any;
  private webSocket: WebSocket | undefined;
  private docTimer: NodeJS.Timeout | undefined;
  private statesTimer: NodeJS.Timeout | undefined;
  private intervalTimeout: number;
  private intervalRetry: number;
  private cursor: Cursor;
  private cursorIndex: number = 0;

  constructor(public props: CodeViewProps) {
    super(props);

    this.textarea = createRef<HTMLTextAreaElement>();
    this.globalStates = null;
    this.webSocket = undefined;
    this.docTimer = undefined;
    this.statesTimer = undefined;
    this.intervalTimeout = 300;
    this.intervalRetry = 100;
    this.cursor = new Cursor("", false);
    this.state = {
      code: "",
      options: {
        lineNumbers: true,
        readOnly: false,
        mode: defaultMode,
        theme: localStorage.getItem("editor#theme") || defaultTheme,
        placeholder: "Please type your code here...",
        value: "",
        autoRefresh: true,
      },
      isFullScreen: false,
    } as CodeviewStates;
    this.onLanguageChange = this.onLanguageChange.bind(this); // 切换编程语言时回调
    this.onThemeChange = this.onThemeChange.bind(this); // 切换主题时回调
    this.toggleFullScreen = this.toggleFullScreen.bind(this);
  }

  toggleFullScreen() {
    const option = this.editor?.getOption("fullScreen");
    void this.editor?.setOption("fullScreen", !option);
    void this.editor?.focus();
    this.setState({ isFullScreen: !this.state.isFullScreen });
  }

  setEditorViewOnly(isViewOnly: boolean) {
    const ops = this.state.options;
    ops.readOnly = isViewOnly;
    this.setState({options: ops});
    if (this.editor) {
      this.editor.setOption("readOnly", isViewOnly);
    }
    this.props.setIsViewOnly(isViewOnly);
  }

  onLanguageChange(msg: string) {
    try {
      changeLanguageOp(this.globalStates, msg);
      void this.editor?.setOption("mode", msg); // 调整编辑器的编程语言
    } catch (err) {
      console.error("LANGUAGE CHANGE ERROR:" + err);
      this.props.onError("LANGUAGE CHANGE ERROR:" + err);
    }
  }

  onThemeChange(msg: string) {
    try {
      localStorage.setItem("editor#theme", msg);
      void this.editor?.setOption("theme", msg);
    } catch (err) {
      console.error("THEME CHANGE ERROR:" + err);
      this.props.onError("THEME CHANGE ERROR:" + err);
    }
  }

  componentDidMount() {
    this.setupDataSync();
    loadThemes();
    loadHighlights();
  }

  componentDidUpdate(prevProps: CodeViewProps, _prevState: CodeviewStates) {
    if (prevProps.completed !== this.props.completed) {
      if (this.props.completed) {
        finishOp(this.globalStates);
        this.props.onFinish(this.editor?.getValue() ?? "");
      }
    }
    if (this.props.isInterviewer === true && this.props.triggerActive === true) { // avoid recursive update
      this.props.resetActiveTrigger();
      this.setEditorViewOnly(!this.state.options.readOnly);
      setViewOnlyOp(this.globalStates);
    }
  }

  setupDataSync() {
    // sharedb 绑定
    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    const sharedbHost = getShareHost();
    const url = `${ws}//${sharedbHost}/${this.props.urlParam}?token=${this.props.token}`;
    const socket = new WebSocket(url);

    this.webSocket = socket;

    const shareConnection = new sharedb.Connection(socket);
    let doc = shareConnection.get(this.props.urlParam, "board");

    // Fetching doc periodically
    let docLimit = 0;

    this.docTimer = setInterval(() => {
      doc = shareConnection.get(this.props.urlParam, "board");
      doc.fetch();

      docLimit += 1;

      console.info("getting doc " + docLimit);

      if (doc.type !== null) {
        doc.subscribe((err?: Error) => {
          if (err) {
            console.error(err);
            throw err;
          }
        });

        this.initializeDoc(doc);

        if (this.docTimer) {
          clearInterval(this.docTimer);
        }
      }
      if (docLimit > this.intervalRetry) {
        this.props.onPermError("docLimit > this.intervalRetry");
        this.componentWillUnmount();
      }
    }, this.intervalTimeout);

    const editElem = this.textarea.current!!;
    this.editor = CodeMirror.fromTextArea(editElem, this.state.options);

    // Fetching states periodically
    let states = shareConnection.get(this.props.urlParam, "states")
    this.statesTimer = setInterval(() => {
      states = shareConnection.get(this.props.urlParam, "states");
      states.fetch();
      if (states.data !== undefined) {
        states.subscribe((err?: Error) => {
          if (err) {
            console.error(err);
            throw err;
          }
        });
        this.initializeStates(states);
        this.globalStates = states;
        if (this.statesTimer) {
          clearInterval(this.statesTimer);
        }
        checkStatesunion(this.globalStates);
        // TODO: why here?
        if (this.props.isInterviewer) {
          if (this.props.user !== undefined) {
            tryAddInterviewerOp(this.globalStates, this.props.user, this.cursor);
            this.cursor.name = this.props.user;
            this.cursor.isInterviewer = true;
          }
        } else {
          const hasSet = sessionStorage.getItem("has_sent_sharedb_username");
          if (!hasSet) {
            sessionStorage.setItem("has_sent_sharedb_username", "yes");
            if (!R.includes(this.props.interviewee, states.data.statesunion[OpIndexTable.Participation])) { //check if has added to sharedb stateunion
              addParticipateOp(this.globalStates, this.props.interviewee || "");
              addcandidateOp(this.globalStates, this.props.interviewee || "")
              addCursorOp(this.globalStates, this.cursor);
            }
            this.cursor.name = this.props.interviewee || "";
            this.cursor.isInterviewer = false;
          }
        }
        this.initializeCursors(states);

      }
    }, this.intervalTimeout);



    socket.addEventListener('close', (event) => {
      console.error("this ws is closing");
      this.props.onPermError("this ws is closing");
    });
  }

  componentWillUnmount() {
    void this.webSocket?.close();

    this.props.dismissError();

    if (this.docTimer) {
      clearInterval(this.docTimer);
    }

    if (this.statesTimer) {
      clearInterval(this.statesTimer);
    }

  }

  setNewLanguage(msg: string) {
    void this.editor?.setOption("mode", msg);
    const ops = this.state.options;
    ops.mode = msg;
    this.setState({ options: ops });
  }

  initializeDoc(doc: any) {
    const editElem = this.textarea.current!!;

    void this.editor?.on("change", function (data: any) {
      // TODO: ??
      editElem.value = data.doc.getValue();
      const evt = document.createEvent("HTMLEvents");
      evt.initEvent("input", false, true);
      editElem.dispatchEvent(evt);
    });

    let text = doc.data.content || "";

    // this.editor.focus();
    const scrollInfo = this.editor?.getScrollInfo();

    void this.editor?.setValue(text);
    void this.editor?.scrollTo(scrollInfo?.left, scrollInfo?.top)

    const binding = new StringBinding(editElem, doc, ['content']);

    binding.setup();

    void this.webSocket?.addEventListener('message', (_) => this.syncMessage(doc));
  }
  initializeCursors(states: any) {
    // checkStatesunion(this.globalStates);
    if (this.props.isInterviewer) {
      this.cursor.color = states.data.statesunion[4].indexOf(this.props.user) === -1 ? colors[states.data.statesunion[4].length + 8] : colors[states.data.statesunion[4].indexOf(this.props.user) + 8];
    } else {
      this.cursor.color = states.data.statesunion[5].indexOf(this.props.user) === -1 ? colors[states.data.statesunion[5].length] : colors[states.data.statesunion[5].indexOf(this.props.user)];
    }
    this.cursor.name = this.props.user || "";
    const changeCursor = this.cursor;
    this.cursorIndex = states.data.statesunion[3].indexOf(this.props.user) === -1 ? states.data.statesunion[3].length : states.data.statesunion[3].indexOf(this.props.user);
    const cursorIndex = this.cursorIndex;
    changeCursorOp(states, cursorIndex, changeCursor);//init cursor state before cursor move
    void this.editor?.on("cursorActivity", function (e) {
      if(isSyncMessage){ //block doc sync: because doc sync will move cursor to (0, 0)
        isSyncMessage=false;
        return;
      }
      if (e.getCursor().line === changeCursor.start.line && e.getCursor().ch === changeCursor.start.ch) return; //delete duplicate operations
      if (e.getCursor() === e.getCursor("to")) {
        changeCursor.start = e.getCursor("from");
        changeCursor.end = e.getCursor("to");
      } else {
        changeCursor.end = e.getCursor("from");
        changeCursor.start = e.getCursor("to");
      }
      changeCursorOp(states, cursorIndex, changeCursor);
    });
  }
  initializeStates(states: any) {
    const message = states.data.statesunion[OpIndexTable.Language];
    const isViewOnly = states.data.statesunion[OpIndexTable.ViewOnly];
    const ops = this.state.options;
    ops.mode = message;
    ops.readOnly = isViewOnly;
    this.setEditorViewOnly(isViewOnly);
    this.setNewLanguage(message);
    this.props.onCurrentOnlineChange(states.data.statesunion[2]);
    this.props.onParticipate(states.data.statesunion[3]);
    this.props.onInterviewer(states.data.statesunion[4]);
    this.props.onCandidate(states.data.statesunion[5]);

    this.setState({
      options: ops,
      // participants: states.data.statesunion[3],
    });



    // @ts-ignore
    states.on('op', (op, source) => {
      if (this.props.completed) {
        console.info("completed, ignore op");
        return;
      }

      const opIndex = getOpIndex(op)

      if (opIndex === OpIndexTable.Language) {
        // Getting opration of changing language
        const message = states.data.statesunion[OpIndexTable.Language];
        this.setNewLanguage(message);
      } else if (opIndex === OpIndexTable.Completeness && !this.props.completed) {
        // Getting opration of completing
        if (!source) {
          void this.webSocket?.close();


          // const ops = this.state.options;
          // ops.readOnly = true; // 变为只读模式
          // ops.autofocus = false; //默认不显示光标

          // ops.value = ""; //savedCode;
          // console.error("tag", ops.value);

          if (!this.props.isInterviewer) {
            this.props.onFinish(this.editor?.getValue() || "");
          }
        }
      } else if (opIndex === OpIndexTable.Connections) {
        // Getting opration of new/close connections
        const currOnline = states.data.statesunion[2];

        if (getOpAddNumber(op) === 1) {
          if (!source) {
            this.props.onCurrentOnlineChange(currOnline);
            this.props.onUserEnter();
          }
        } else {
          this.props.onCurrentOnlineChange(currOnline);
          this.props.onUserLeave();
        }
      } else if (opIndex === OpIndexTable.Participation) {
        this.props.onParticipate(states.data.statesunion[3]);
        // this.setState({ participants: states.data.statesunion[3] }, () => {
        //   localStorage.setItem(`${this.props.urlParam}#participants`, JSON.stringify(this.state.participants));
        // });
      } else if (opIndex === OpIndexTable.ViewOnly) {
        if (!this.props.isInterviewer) {
          const isViewOnly = states.data.statesunion[6];

          this.setEditorViewOnly(isViewOnly);
        }
      }
      else if (opIndex === OpIndexTable.Interviewers) {
        this.props.onInterviewer(states.data.statesunion[4]);
      }
      else if (opIndex === OpIndexTable.Candidates) {
        this.props.onCandidate(states.data.statesunion[5]);
      }
      else if (opIndex === OpIndexTable.Cursors) {
        //clear all marks than add cursors
        //states.data.statesunion[7]: cursor list
        const marks = this.editor?.getAllMarks();
        for (var i = 0; marks && i < marks?.length; i++) {
          marks[i].clear();
        }

        for (var i = 0; i < states.data.statesunion[7].length; i++) {
          if (i != this.cursorIndex) { //remove current user cursor mark
            let end = states.data.statesunion[7][i].end;
            let start = states.data.statesunion[7][i].start;
            if (start.line > end.line) {
              const tmp = end;
              end = start;
              start = tmp;
            } else if (start.line === end.line) {
              if (start.ch > end.ch) {
                const tmp = end;
                end = start;
                start = tmp;
              }
            }
            //selection sync
            const selectionColor = tinycolor(states.data.statesunion[7][i].color).setAlpha(0.3).toString();
            const selectionMark=this.editor?.markText(start, end, {
              css: "background:" + selectionColor
            });
            //insert cursors
            const cursorNode = this.createCursorNode(states.data.statesunion[7][i].name, states.data.statesunion[7][i].color, states.data.statesunion[7][i].end.line);
            const cursorMark=this.editor?.setBookmark(states.data.statesunion[7][i].end, {
              widget: cursorNode
            }
            );
          }
        }
      }
    });
  }

  createCursorNode(name: string, color: string, line: number): HTMLElement {
    const element = document.createElement("span");
    element.classList.add("cm-cursor");
    element.id = `cm-cursor-${this.props.user}`;
    element.innerHTML =
      `<span class=".cm-selection"></span><span class="cm-cursor-caret"></span><div class="cm-cursor-flag"><small class="cm-cursor-name"></small></div>`
    const caretElement = element.getElementsByClassName("cm-cursor-caret")[0] as HTMLElement;
    const flagElement = element.getElementsByClassName('cm-cursor-flag')[0] as HTMLElement;

    flagElement.style.backgroundColor = color;
    caretElement.style.backgroundColor = color;
    caretElement.style.color = color;
    element.getElementsByClassName('cm-cursor-name')[0].textContent = name;

    const _hideDelay = `${1000}ms`;
    const _hideSpeedMs = 400;
    flagElement.style.transitionDelay = _hideDelay;
    flagElement.style.transitionDuration = `${_hideSpeedMs}ms`;
    element.addEventListener("mouseover", function () {
      if (line === 0) {
        flagElement.style.transform = "translate3d(" + (element.offsetLeft - 4.5).toString() + "px, -20%, 0)";
      }
      else {
        flagElement.style.transform = "translate3d(" + (element.offsetLeft - 4.5).toString() + "px, -180%, 0)";
      }
    });

    return element;
  }

  syncMessage(doc: any) {
    if (!this.editor) {
      return;
    }

    if (doc.data && doc.data.content !== this.editor.getValue()) {
      isSyncMessage=true; //set doc sync flag true when change doc
      const { line, ch } = this.editor.getCursor();
      if (!doc.data.content) doc.data.content = "";
      // this.editor.focus();
      const scrollInfo = this.editor.getScrollInfo();
      this.editor.setValue(doc.data.content); //setvalue will move cursor to (0, 0)
      this.editor.scrollTo(scrollInfo.left, scrollInfo.top)
      this.editor.setCursor({ line, ch });//restore the position of the cursor
    }
  }

  render() {
    return (
      <>
        {this.state.options.readOnly ?
          <div className="row">
            <Badge color="primary" pill>Read only</Badge>
          </div> :
          <div>
            <div className="dropdown">
              <DropDowns
                onLanguageChange={this.onLanguageChange}
                defaultLanguage={this.state.options.mode}
                onThemeChange={this.onThemeChange}
                defaultTheme={this.state.options.theme}
                onToggleFullScreen={this.toggleFullScreen}
                showLanguageDropdown={this.props.showLanguageDropdown}
              />
            </div>
            {this.state.isFullScreen &&
              <div className="exit-fullscreen fa fa-compress" onClick={this.toggleFullScreen} />}
          </div>}

        <div className="codearea">
          <textarea id="editor" ref={this.textarea} autoFocus={false} />
        </div>
      </>
    );
  }
}

export default CodeView;
