import React from 'react';
import { Badge } from 'reactstrap';

interface InterviewStatusProps {
  complete: boolean;
}

/* Badge - 显示面试状态 Ongoing/Completed */
export default function InterviewStatus({ complete }: InterviewStatusProps) {
  if (complete === true) {
    return (
      <Badge color="primary">Finished</Badge>
    );
  } else {
    return (
      <Badge color="success">Ongoing</Badge>
    );
  }
}
