import React from "react";
import { useIsAuthenticated, useMsal, withMsal } from "@azure/msal-react"
import { Redirect } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";



export interface AuthComponentProps {
  accessToken: string | null;
}

export function useAuth(HocComponent: any) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const component = function (props: any) {

    if (isAuthenticated) {
      return (<HocComponent
        {...props} />);
    }
    else if (inProgress !== InteractionStatus.None) {
      return <div> Loading ... </div>;
    }
    else return <Redirect to="/home"></Redirect>;
  }

  return withMsal(component);
}
