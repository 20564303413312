interface LanguageOption {
  id: string;
  name: string;
}

export const languages: Array<LanguageOption> = [
  { id: "plaintext", name: "Plain text" },
  { id: "javascript", name: "JavaScript" },
  { id: "text/x-java", name: "Java" },
  { id: "python", name: "Python" },
  { id: "text/x-c++src", name: "C++" },
  { id: "text/x-csharp", name: "C#" },
  { id: "text/x-go", name: "Go" },
  { id: "text/x-kotlin", name: "Kotlin" },
  { id: "text/x-swift", name: "Swift" },
  { id: "text/rust", name: "Rust" },
  { id: "text/typescript", name: "TypeScript" },
];

