export function getHostname() {
    const urlHost = document.location.host;
    const domain = `${document.location.protocol}//${urlHost}`;

    return { urlHost, domain };
}

export function getWsProtocol() {
    const urlHost = document.location.host.split(":")[0];
    if (urlHost === "localhost") {
        return "ws:"
    }

    if (document.location.protocol === "http:") {
        return "ws:"
    } else if (document.location.protocol === "https:") {
        return "wss:"
    }
}

export function getShareHost() {
  const urlHost = document.location.host.split(":")[0];
  if (urlHost === "localhost")
    return urlHost + ":8081"
  else
    return "node." + urlHost;
}
