import React from "react";

interface EmptyInterviewProps {
  text: string;
}

/* 面试记录为空时显示 */
export function EmptyInterview({ text }: EmptyInterviewProps) {
  return (
    <div className="interview-wrapper">
      <div className="empty-interview">
        <img src="/assets/non.png" className="mt-2" width="220px" height="160px" alt="empty logo"></img>
        <div>{text}</div>
      </div>
    </div>
  );
}
