import React, { useState } from "react";
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem } from "reactstrap";
import { navigateToCareer } from "../utils/navigation";

/* 404 not found page */
export default function () {
  const [isOpen, setIsOpen] = useState(false);

  const onNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="master">
      <div className="shadow-lg rounded-lg bg-card text-white">
        <Navbar expand="md">
          <NavbarBrand href="/">
            <img src="/assets/logo2.png" width="160px" height="40px" alt="logo" />
          </NavbarBrand>
          <NavbarToggler onClick={onNavbarToggle}>
            <span color="white" className="fa fa-bars" ></span>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem className="ml-3 cursor" onClick={navigateToCareer}>
                Careers
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        <div className="d-flex justify-content-between mt-5">
          <div className="d-flex flex-row">
            <div className="ml-5">
              <br /><br />
              <h1>404 Not Found!</h1>
              <div className="mt-3 mb-5">
                <p className="lead">
                  Sorry, the interview page you're looking for is expired or does not exist.
                </p>
                <p className="lead">
                  Please try other pages.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
