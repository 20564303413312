import "./HistoryContainer.scss";

import React, { useEffect, useState } from "react";
import { Progress, TabContent, TabPane } from "reactstrap";
import Pages from "../components/PagesComponent";
import { EmptyInterview } from "../components/EmptyInterviewComponent";
import CodeviewHeaderMenu, { Page } from "../components/HeaderMenuComponent";
import { RouteComponentProps } from "react-router-dom";
import { pca } from "../index"
import { findBoardsByInterviewer } from "../services/boardService";


type NavBarProps = { choices: string[], activeItem: number, onItemClick: (i: number) => void };

function NavBar(props: NavBarProps) {
  return <div className='one-nav-bar'>
    {
      props.choices.map((choice, i) =>
        <div className={i === props.activeItem ? 'one-nav-item active' : 'one-nav-item'} onClick={() => props.onItemClick(i)} key={i}>
          {choice}
        </div>)
    }
  </div>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HistoryProps extends RouteComponentProps {
}

const tabs = ["Ongoing", "Finished", "All"];

const hints = [
  "Your interview history is empty",
  "Your finished interview history is empty",
  "Your ongoing interview history is empty",
];

/*
    History界面
*/
export default function History({ history }: HistoryProps) {
  const [activeTab, setActiveTab] = useState(0);
  const [dataset, setDataset] = useState<Array<any> | null>(null);

  // 加载面试
  useEffect(() => {
    const username = localStorage.getItem("username");
    const email = localStorage.getItem("email");

    if (pca.getActiveAccount()) {
      findBoardsByInterviewer(username || "", email || "")
        .then((response: any) => {
          if (response && response.data) {
            setDataset(response.data);
          }
          else {
            console.error(response);
          }
        });
    }
  }, []);

  // 切换tab
  const setToggle = function (id: number) {
    setActiveTab(id);
  }

  // 数据正在加载，显示Loading...
  if (dataset === null) {
    return (
      <div>
        <div className="shadow-lg rounded-lg  code-card text-white">
          <div className="ml-5">
            <br /><br />
            <h1>Loading......</h1> <Progress animated value={50} />
          </div>
        </div>
      </div>
    )
  } else {


    const dataset_ = [
      dataset.filter((data) => data.completed === false),
      dataset.filter((data) => data.completed === true),
      dataset,
    ];
    const interviewer = localStorage.getItem("interviewer") || "";

    return (
        <div className="shadow-lg rounded-lg his-card text-white">
          {/* Header */}
          <CodeviewHeaderMenu
            isInterviewer={true}
            current={Page.History}
            participants={""}
            interviewers={[interviewer]}
            candidates={[]}
            history={history} />
          {/* Tab */}
          <NavBar choices={tabs} onItemClick={setToggle} activeItem={activeTab}/>

          <TabContent activeTab={activeTab}>
            {
              tabs.map((_, i) =>
                <TabPane tabId={i} key={i}>
                  {
                    dataset_[i].length === 0 ?
                      <EmptyInterview text={hints[i]} />
                      :
                      <Pages
                        dataSet={dataset_[i]}
                        deleteData={setDataset}
                      />
                  }
                </TabPane>
              )
            }
          </TabContent>
        </div>
    );
  }
}
