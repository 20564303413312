export interface Theme {
  id: string;
  name: string;
}

export const themes: Array<Theme> = [
  { id: 'default', name: 'Default' },
  { id: 'night', name: 'Night' },
  { id: 'darcula', name: 'Darcula' },
  { id: 'material', name: 'Material' },
  { id: 'material-ocean', name: 'Material Ocean' },

  { id: 'yeti', name: 'Yeti' },
  { id: 'eclipse', name: 'Eclipse' },
  { id: 'mdn-like', name: 'Mdn like' },
  { id: 'paraiso-light', name: 'Paraiso Light' },
];