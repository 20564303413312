/* eslint-disable @typescript-eslint/no-var-requires */
import "./CodeviewContainer.scss";
import React, { Component } from "react";
import { Alert, Button } from "reactstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Switch from "react-switch";
import InitProgressBar from "../components/InitProgressBar";
import NotFound from "../components/NotFoundComponent";
import ReadonlyModeView from "../components/ReadOnlyCodeViewComponent";
import CodeviewHeaderMenu, { Page } from "../components/HeaderMenuComponent";
import { ErrorModal, FinishModal, TimeoutModal, ParticipantPromptModal, PermErrorModal } from "../components/ModalComponents";

import 'codemirror/addon/display/fullscreen';
import 'codemirror/addon/display/fullscreen.css';
import { pca } from "../index"

import CodeView from "../components/CodeviewComponent";
import { addParticipate, checkInterviewer, getContent, initBoard, makeComplete } from "../services/boardService";
import Timer from "../components/TimerComponent";


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CodeViewProps extends RouteComponentProps {

}

enum Hint {
  Connected = "A new user connected.",
  Reconnected = "A user reconnected.",
  Disconnected = "A user disconnected."
}

interface CodeviewStates {
  token: string | null;
  user: string | undefined;
  userValid: boolean;
  ready: boolean;
  completed: boolean;
  willComplete: boolean,
  existing: boolean | null;
  code: string;
  isInterviewer: boolean;
  interviewerName: string;
  interviewerEmail: string;
  hint: Hint | null;
  isErrorModalOpen: boolean;
  permErrorModal: boolean;
  isCompleteModalOpen: boolean;
  isParticipantModalOpen: boolean;
  errorMsg: any;
  participants: Array<any>;
  candidates: Array<any>;
  interviewers: Array<any>;
  isFullScreen: boolean;
  urlParam: string;
  currOnline: number;
  participantNameConfirmed: boolean;
  isAlarmOut: boolean; // timeout to show modal
  isActiveTrigger: boolean; // Whether the viewOnly change is actively triggered by the user
  isViewOnly: boolean;
}

let interval: NodeJS.Timeout;
class CodeViewContainer extends Component<CodeViewProps, CodeviewStates> {
  board_token: string;

  constructor(props: CodeViewProps) {
    super(props);
    const match = props.match as any;
    this.state = {
      token: null,
      user: undefined,
      userValid: false,
      ready: false,
      completed: false,
      willComplete: false,
      existing: null,
      code: "",
      isInterviewer: !!pca.getActiveAccount(),
      interviewerName: "",
      interviewerEmail: "",
      isErrorModalOpen: false,
      isCompleteModalOpen: false,
      isParticipantModalOpen: false,
      permErrorModal: false,
      errorMsg: {},
      isFullScreen: false,
      participants: [],
      interviewers: [],
      candidates: [],
      hint: null,
      urlParam: match.params["url"] as string,
      currOnline: 0,
      participantNameConfirmed: false,
      isAlarmOut: false,
      isActiveTrigger: false,
      isViewOnly: false
    } as CodeviewStates;
    this.toggleCompleteModal = this.toggleCompleteModal.bind(this);
    this.toggleTimeOutModal = this.toggleTimeOutModal.bind(this);
    this.toggleErrorModal = this.toggleErrorModal.bind(this);
    this.handleComplete = this.handleComplete.bind(this); // 完成面试
    this.setActivateTrigger = this.setActivateTrigger.bind(this);
    this.dismissHint = this.dismissHint.bind(this);
    this.onParticipantNameConfirmed = this.onParticipantNameConfirmed.bind(this);
    this.toggleParticipantModal = this.toggleParticipantModal.bind(this);
    this.board_token = new URLSearchParams(this.props.location.search).get("token") ?? "";
    // this.toggleFullScreen = this.toggleFullScreen.bind(this);
    this.showHint = this.showHint.bind(this);
  }

  // static getDerivedStateFromProps(nextProps: CodeViewProps, prevState: CodeviewStates) {
  //   const params = nextProps.match.params as any;
  //   console.log("getDerivedStateFromProps", params, prevState.urlParam);
  //   if (params.url !== prevState.urlParam) {
  //     return {
  //       urlParam: params.url,
  //     };
  //   }
  //   return null;
  // }

  updateUsername = (name: string) => {
    this.setState({ user: name }, this.validateParticipant)
  }

  // 进行了长度检查 => 长度>=1
  validateParticipant() {
    const { user: participater } = this.state;

    const valid = (!!participater && participater.length > 0);
    const errorMsg = this.state.errorMsg;
    if (!valid) {
      errorMsg.participater = "Please input your name";
    }

    this.setState({ userValid: valid, errorMsg })
  }

  onParticipantNameConfirmed(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (this.state.userValid) {
      this.setState({ participantNameConfirmed: true });
      this.toggleParticipantModal();

      localStorage.setItem("username", this.state.user || "");

      console.info("will set username", this.state.user);
      localStorage.setItem(`${this.state.urlParam}#user`, this.state.user || "");

      // this.setState({
      //   participants: [...this.state.participants, this.state.user],
      // });


      this.setState({ ready: true });
      addParticipate(this.state.urlParam, this.state.user || "")
        .then((res) => {
          this.setState({ ready: true });
        })
        .catch((err) => {
          console.error(err);
          this.toggleErrorModal();
        })

      // this.editor.focus(); // Focus after submitting name
    } else {
      this.setState({
        ready: false,
        errorMsg: {
          'participater': 'Please input your name'
        },
      })
    }
  }


  toggleCompleteModal() {
    this.setState(({ isCompleteModalOpen: !this.state.isCompleteModalOpen }));
  }

  toggleTimeOutModal() {
    this.setState(({ isAlarmOut: !this.state.isAlarmOut }));
  }

  toggleParticipantModal() {
    if (this.state.isParticipantModalOpen && !this.state.userValid) {
      return;
    }

    this.setState(({ isParticipantModalOpen: !this.state.isParticipantModalOpen }));
  }

  toggleErrorModal() {
    this.setState(({ isErrorModalOpen: !this.state.isErrorModalOpen }));
  }

  dismissHint() {
    this.setState({ hint: null });
  }

  setIsViewOnly(flag: boolean) { // get isViewOnly property from children componnet
    this.setState({isViewOnly: flag})
  }
  setActivateTrigger() {
    this.setState({isActiveTrigger: true});
  }

  resetActiveTrigger() {
    this.setState({isActiveTrigger: false});
  }

  /**
   * Only visible to interviewer
   */
  handleComplete() {
    this.setState({
      willComplete: true,
    });
  }

  showHint(hint: Hint) {
    this.setState({ hint: hint });
    setTimeout(() => {
      if (this.state.hint === hint) {
        this.dismissHint();
      }
    }, 6000);
  }

  /*
     初始化
      - 首先检查白板是否完成(调用/get_content)：
          * [url匹配，已经完成]，以只读方式显示内容
          * 其他情况，进行正在面试的url匹配
      - 匹配当前正在面试的白板(调用/init)
          * [返回白板不存在] 404
          * [存在，已完成] 404
          * [存在，尚未完成] 返回协同编辑界面 [在协同编辑界面]
  */
  /**
   * Initialize states:
   * 1.
   * 2.
   * 3.
   */
  async componentDidMount() {
    await this.setupBoardStep1();
  }

  async setupBoardStep1() {
    console.debug('Codeview: componentDidMount');
    const account = pca.getActiveAccount();

    if (account) {
      localStorage.setItem("username", account.name || "");
      localStorage.setItem("email", account.username || "");
      localStorage.setItem("interviewer", account.name || "");
    }

    const email = localStorage.getItem("email");
    if (!email) {
      console.debug("local storage email missing");
      this.setupBoardStep2(false);
      return;
    }

    try {
      const interviewer = localStorage.getItem("interviewer") || "";
      const username = localStorage.getItem(`username`) || "";
      this.setState({user: username});
      const res = await checkInterviewer(this.state.urlParam, interviewer || "", email);
      if (res.status === 200) {
        let tokens = {} as any;
        if (localStorage.getItem("tokens")) {
          tokens = JSON.parse(localStorage.getItem("tokens") || "");
        }

        const urlParam = this.state.urlParam;
        tokens[urlParam] = { "token": res.data.token, "feedback": "" };
        localStorage.setItem("tokens", JSON.stringify(tokens));
        this.setState({
          token: res.data.token,
        });

        this.setupBoardStep2(true);
        return;
      }
    } catch (e) {
      console.error(e);
    }

    this.setupBoardStep2(false);
  }

  setupBoardStep2(isInterviewer: boolean) {
    console.debug("start setup 2: is interviewer? " + isInterviewer);
    const username = localStorage.getItem(`username`) || "";
    this.setState({
      isInterviewer: isInterviewer,
      user: username,
      ready: true,
      participants: JSON.parse(localStorage.getItem(`${this.state.urlParam}#participants`) || "[]"),
      interviewers: JSON.parse(localStorage.getItem(`${this.state.urlParam}#interviewers`) || "[]"),
      candidates: JSON.parse(localStorage.getItem(`${this.state.urlParam}#candidates`) || "[]"),
    });

    if (!username) {
      this.toggleParticipantModal();
    } else {
      this.setState({
        participantNameConfirmed: true,
      });
    }

    this.setupBoardStep3(isInterviewer);
  }

  setupBoardStep3(isInterviewer: boolean) {
    const BOARD_COMPLETED = "the board has completed";
    const BOARD_NOT_EXIST = "board does not exist";

    Promise
      .all([getContent(this.state.urlParam), initBoard(this.state.urlParam)])
      .then(([finishRes, initRes]) => {

        if (finishRes && finishRes.data !== "") {
          this.setState({
            completed: true,
            code: finishRes.data.content,
            existing: true,
          });

          return;
        }

        if (this.board_token === "" || initRes.data === BOARD_NOT_EXIST // 当前界面不存在，返回404 | Page does not exist, return 404
          || initRes.data === BOARD_COMPLETED) { // 当前白板已经完成，不允许再通过url param访问，只能通过perm param访问（即上面的第一种情况） | Completed board can only be accessed using perm param, and url param is aborted.
          this.setState({ existing: false });
          return;
        }

        // Interviewing => fetch current code content (res.data.content) and language using (res.data.language)
        // ops.mode = initRes.data.language;

        // Board exsits, ongoing (finish is false, exist is true)
        this.setState({
          interviewerName: initRes.data.interviewerShowname,
          code: initRes.data.content,
          completed: false,
          existing: true,
        });

      }).catch((err) => {
        console.error(err);
        this.toggleErrorModal();
      });
  }

  componentWillUnmount() {
    this.setState({ isErrorModalOpen: false });
    clearInterval(interval); // clear timer
  }

  private getParticipants() {
    let name = "";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // const participants = JSON.parse(localStorage.getItem(`${this.state.urlParam}participants`));
    const participants = this.state.participants;
    if (participants) {
      for (const val of participants) {
        name += val + ", ";
      }
      if (name.length > 0) {
        name = name.substring(0, name.length - 2);
      }
    }
    return name;
  }
  private getInterviewers() {
    const interviewer = localStorage.getItem("interviewer") || "";
    if (this.state.interviewers === undefined || this.state.interviewers.length === 0) { //adapt version without interviewers
      return [interviewer]
    }
    return this.state.interviewers;

  }
  private getCandidates() {
    if (this.state.interviewers === undefined || this.state.interviewers.length === 0) { //adapt version without candidates
      const participants = this.state.participants;
      const interviewer = localStorage.getItem("interviewer") || "";
      if(participants){
        return participants.filter((e)=>{return e!=interviewer}); //remove interviewer
      }
      return [];
    }
    return this.state.candidates;
  }
  onError(err: string) {
    this.setState({ errorMsg: err });
    if (!this.state.permErrorModal) {
      this.toggleErrorModal();
    }
  }

  onUserEnter() {
    this.showHint(Hint.Connected);
  }

  onUserLeave() {
    this.showHint(Hint.Disconnected);
  }

  onCurrentOnlineChange(online: number) {
    this.setState({
      currOnline: online
    });
  }

  onPermError() {
    this.dismissError();
    this.setState({
      permErrorModal: true,
    });
  }

  dismissError() {
    this.setState({
      isErrorModalOpen: false,
    });
  }

  onFinish(code: string) {
    if (this.state.isInterviewer) {

      makeComplete({
        "urlParam": this.state.urlParam,
        "token": this.state.token,
        "feedback": "",
        "content": code,
      })
        .then((res) => {
          this.toggleCompleteModal();

          // 需要将permUrl participants保存
          const key = res.data.permParam + "participants";
          const val = JSON.stringify(this.state.participants);
          localStorage.setItem(key, val);

          const interviewerKey = res.data.permParam + "interviewers";
          const interviewerVal = JSON.stringify(this.state.interviewers);
          localStorage.setItem(interviewerKey, interviewerVal);

          const candidateKey = res.data.permParam + "candidates";
          const candidateVal = JSON.stringify(this.state.candidates);
          localStorage.setItem(candidateKey, candidateVal);
          // 界面跳转到永久白板界面
          const newRoute = "/interview/" + res.data.permParam;

          this.setState({
            completed: true,
            code: code,
          }, () => {
            this.props.history.push(newRoute);
          });
        }).catch((err) => {
          console.error(err);
          this.toggleErrorModal();
        });
    } else {
      this.setState({ completed: true });
      this.props.history.push("/finish");
    }
  }

  onParticipate(participants: any[]) {
    this.setState({
      participants,
    }, () => {
      localStorage.setItem(`${this.state.urlParam}#participants`, JSON.stringify(participants));
    });
  }
  onInterviewer(interviewers: any[]) {
    this.setState({
      interviewers,
    }, () => {
      localStorage.setItem(`${this.state.urlParam}#interviewers`, JSON.stringify(interviewers));
    });
  }
  onCandidate(candidates: any[]) {
    this.setState({
      candidates,
    }, () => {
      localStorage.setItem(`${this.state.urlParam}#candidates`, JSON.stringify(candidates));
    });
  }

  render() {
    if (this.state.completed === null && this.state.existing === null) {
      return (<InitProgressBar />)   // 初始化加载进度条
    } else if (this.state.existing === false) {
      return (<NotFound />) // 白板不存在，返回404界面
    } else if (this.state.completed === true) {
      const name = this.getParticipants();
      const interviewers = this.getInterviewers();
      const candidates = this.getCandidates();
      return (
        <ReadonlyModeView
          interviewer={this.state.isInterviewer}
          code={this.state.code}
          name={name}
          interviewers={interviewers}
          candidates={candidates}
          history={this.props.history} /> // 永久url 或 24h内可访问 的情况 => 以只读方式显示白板内容
      )
    } else {
      const hint = this.state.hint;
      const participants = this.getParticipants();
      const interviewers = this.getInterviewers();
      const candidates = this.getCandidates();
      return (
        <div className="one-code-container">
          <div className="shadow-lg code-card text-white" hidden={!this.state.ready}>

            <CodeviewHeaderMenu
              current={Page.Interview}
              isInterviewer={this.state.isInterviewer}
              interviewerDisplayname={this.state.interviewerName}
              participants={participants}
              interviewers={interviewers}
              candidates={candidates}
              history={this.props.history} />

            {
              this.state.isInterviewer ?
                <CodeView
                  urlParam={this.state.urlParam}
                  token={this.board_token}
                  isInterviewer={this.state.isInterviewer}
                  completed={this.state.willComplete}
                  triggerActive={this.state.isActiveTrigger}
                  user={this.state.user}
                  showLanguageDropdown={true}
                  onError={this.onError.bind(this)}
                  onUserEnter={this.onUserEnter.bind(this)}
                  onUserLeave={this.onUserLeave.bind(this)}
                  onCurrentOnlineChange={this.onCurrentOnlineChange.bind(this)}
                  onPermError={this.onPermError.bind(this)}
                  dismissError={this.dismissError.bind(this)}
                  onFinish={this.onFinish.bind(this)}
                  onParticipate={this.onParticipate.bind(this)}
                  setIsViewOnly={this.setIsViewOnly.bind(this)}
                  resetActiveTrigger={this.resetActiveTrigger.bind(this)}
                  onInterviewer={this.onInterviewer.bind(this)}
                  onCandidate={this.onCandidate.bind(this)}
                />
                :
                this.state.participantNameConfirmed ?
                  <CodeView
                    urlParam={this.state.urlParam}
                    token={this.board_token}
                    isInterviewer={this.state.isInterviewer}
                    interviewee={this.state.user}
                    completed={this.state.willComplete}
                    triggerActive={this.state.isActiveTrigger}
                    user={this.state.user}
                    showLanguageDropdown={false}
                    onError={this.onError.bind(this)}
                    onUserEnter={this.onUserEnter.bind(this)}
                    onUserLeave={this.onUserLeave.bind(this)}
                    onCurrentOnlineChange={this.onCurrentOnlineChange.bind(this)}
                    onPermError={this.onPermError.bind(this)}
                    dismissError={this.dismissError.bind(this)}
                    onFinish={this.onFinish.bind(this)}
                    onParticipate={this.onParticipate.bind(this)}
                    setIsViewOnly={this.setIsViewOnly.bind(this)}
                    resetActiveTrigger={this.resetActiveTrigger.bind(this)}
                    onInterviewer={this.onInterviewer.bind(this)}
                    onCandidate={this.onCandidate.bind(this)}
                  />
                  :
                  <>Waiting for interviewee...</>
            }

            <div className="one-hint">
              {hint && <Alert color={hint === Hint.Disconnected ? "danger" : "success"} toggle={this.dismissHint}>
                <span className={hint === Hint.Disconnected ? "fa fa-exclamation-triangle" : "fa fa-info-circle"} />
                {hint}
              </Alert>}
            </div>

            <div className="footer-bar" style={{ marginTop: "0px", width:'100%'}}>
              {
                this.state.isInterviewer &&
                <div className="row col-12 align-div">
                  <div className="align-div">
                    <Button
                      className="btn-light text-primary"
                      onClick={this.toggleCompleteModal}>
                      <span className="fa fa-power-off" /> Complete
                    </Button>
                      <div className="align-div" style={{ marginLeft: "50px" }}>
                        <Switch 
                          checked={this.state.isViewOnly} 
                          onChange={this.setActivateTrigger} 
                          onColor="#1890FF"
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={18}
                          width={40}
                          className="react-switch"
                          id="material-switch" />
                        <span className="text-center" style={{ marginLeft: "5px", verticalAlign: "middle" }}> Read-Only </span>
                      </div>
                  </div>
                  <div className="align-div"> 
                    <Timer setAlarmOut={this.toggleTimeOutModal}></Timer>
                    <span className="text-center" style={{ marginLeft: "50px", verticalAlign:"middle"}}> {"Current online: " + this.state.currOnline.toString().padStart(3, "\xa0")} </span>
                  </div>

                </div>
              }
            </div>
          </div>

          <FinishModal
            isOpen={this.state.isCompleteModalOpen}
            toggle={this.toggleCompleteModal}
            handler={this.handleComplete} />

          <TimeoutModal
            isOpen={this.state.isAlarmOut}
            toggle={this.toggleTimeOutModal} />

          <ErrorModal isOpen={this.state.isErrorModalOpen} toggle={this.toggleErrorModal} />

          <PermErrorModal isOpen={this.state.permErrorModal} />

          <ParticipantPromptModal
            isParticipantModalOpen={this.state.isParticipantModalOpen}
            toggleParticipantModal={this.toggleParticipantModal}
            participantNameConfirmed={this.onParticipantNameConfirmed}
            updateUsername={this.updateUsername}
            user={this.state.user}
            userValid={this.state.userValid}
            pca={pca}
            errorMsg={this.state.errorMsg.participater}
          />
        </div>
      );
    }
  }
}

export default withRouter(CodeViewContainer);
