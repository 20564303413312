import axios from "axios";
import { pca } from "../index"
import { createBoard } from "../services/boardService";

axios.defaults.withCredentials = true;

export async function createInterview(interviewer: any) {
  const account = pca.getActiveAccount();
  if (!account) {
    return `/home`;
  }

  const res = await createBoard(account.name || "", interviewer, account.username);

  /*
     store token in localStorage
     e.g. tokens[url]={"token":token, "feedback":""}
  */
  let tokens = {} as any;
  if (localStorage.getItem("tokens")) {
    tokens = JSON.parse(localStorage.getItem("tokens") || "");
  }

  const urlParam = res.data.board.urlParam;
  tokens[urlParam] = { "token": res.data.token, "feedback": "" };

  localStorage.setItem("tokens", JSON.stringify(tokens));

  return `/interview/${urlParam}?token=${res.data.token}`;
}
