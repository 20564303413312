import React from 'react';
import Avatar from 'react-avatar';
import { Popover, PopoverBody, PopoverHeader, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import { colors } from '../config/colors';

interface UserListProps {
  isInterviewer: boolean; //userList state
  members: string[];
  username: string;
}
export default function UserListComponent({ isInterviewer, members, username }: UserListProps) {
  if (members === undefined) members = [];
  const userIndex = members.indexOf(username);
  //get the first two char
  const getAvatarName = (name: string) => {
    const space2Index = name.indexOf(' ', name.indexOf(' ') + 1); //the second space index
    if (space2Index == -1) {
      if(name.indexOf(' ')==-1){
        return name.length<=1?name:name[0]+' '+name.substring(1);
      }
      return name;
    } else { //only show 2 char
      return name.substring(0, space2Index);
    }
  };
  //render user list
  const renderList = () => {
    const moreMembers: string[] = [];
    for (let i = 0; i < members.length; i++) {
      moreMembers.push(members[i]);
    }
    return (
      <div style={{ maxHeight: 'calc(60vh)', overflowY: 'scroll' }}>
        {moreMembers.map((member, index) => (
          index !== userIndex && !(userIndex === -1 && index === 0) &&
          <div
            key={member + index}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4,
            }}
          >
            <Avatar
              style={{ marginLeft: '5px' }}
              color={colors[isInterviewer ? index + 8 : index]} //0-8 for candidates, 9-end for interviewers
              size="30"
              round={true}
              name={getAvatarName(member)}
            />
            <span style={{ marginLeft: '10px' }}>{member}</span>
          </div>
        ))}
      </div>
    );
  };
  // (+X) icon
  const renderMore = () => {
    const Popoverid = "moreUserTooltip" + isInterviewer.toString();
    if (members.length > 1) {
      return (
        <span className='m-1'>
          <span className="editor-ot-more-button" id={Popoverid}>
            <Avatar
              color='#aFaFaF'
              round={true}
              size="32"
              name={"+ " + (members.length - 1).toString()}
            />
          </span>
          <UncontrolledPopover
            style={{ marginBottom: '2px' }}
            target={Popoverid}
            trigger="hover"
            placement='bottom'
            delay={{ show: 0, hide: 300 }}
          >
            <PopoverHeader>
              {members.length - 1}+{" candidates "}
            </PopoverHeader>
            {renderList()}
          </UncontrolledPopover>
        </span>

      );
    }
    return;
  };
  //showing avators
  return (
    <span className="editor-users">
      {
        (userIndex === -1) && !!members.length &&
        <span className='m-1' key={members[0] + 0} >
          <Avatar
            color={colors[isInterviewer ? 8 : 0]} //0-8 for candidates, 9-end for interviewers
            size="32"
            round={true}
            name={getAvatarName(members[0])}
            title={isInterviewer ? "Interviewer: " + members[0] : "Candidate: " + members[0]}
          />
        </span>
      }
      {
        (userIndex !== -1) && !!members.length &&
        <span className='m-1' key={members[userIndex] + userIndex} >
          <Avatar
            color={colors[isInterviewer ? userIndex + 8 : userIndex]} //0-8 for candidates, 9-end for interviewers
            size="32"
            round={true}
            name={getAvatarName(members[userIndex])}
            title={isInterviewer ? "Interviewer: " + members[userIndex] : "Candidate: " + members[userIndex]}
          />
        </span>
      }
      {renderMore()}
    </span>
  );
};

