import "bootstrap/dist/css/bootstrap.min.css";
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'font-awesome/css/font-awesome.css';
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./config/msal"; // MSAL configuration


export const pca = new PublicClientApplication(msalConfig);
const accounts = pca.getAllAccounts();
if (accounts.length > 0) {
  pca.setActiveAccount(accounts[0]);
}

pca.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    pca.setActiveAccount(account);
  }
});


pca.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    localStorage.clear();
  }
});


// Component
const AppProvider = () => (
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>
);

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
