function navigate(url: string) {
  return () => window.location.href=url;
}

export const navigateToHome = (history?: any) => {
  if (history) {
    history.push("/");
  } else {
    navigate("/home");
  }
}

function navigateOpen(url: string) {
  return () => window.open(url);
}

export const navigateToCareer = navigateOpen("https://careers.microsoft.com/us/en");

export const navigateToFeedback = navigateOpen("https://msrecruit.microsoft.com/iv/dashboard");
