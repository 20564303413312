import CodeMirror from "codemirror";
import { colors } from "../config/colors";
export default class Cursor{
  public name: string;
  public start: CodeMirror.Position;
  public end: CodeMirror.Position;
  public isInterviewer: boolean;
  public color: string;
  private static cursorIndex: number =0;

  public constructor(name: string, isInterviewer: boolean) {
    this.name = name;
    this.start={line:0, ch:0};
    this.end={line:0, ch:0};
    this.isInterviewer=isInterviewer;
    this.color=colors[Cursor.cursorIndex++];
  }
}
