import React, { useEffect, useState } from 'react';
import { Button, UncontrolledTooltip, Nav, Navbar, NavbarToggler, Collapse, NavItem, NavbarBrand, NavbarText, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { navigateToCareer, navigateToFeedback } from "../utils/navigation";
import { AuthComponentProps } from "../utils/withAuth";
import { ErrorModal, StartInterviewPropmptModal } from '../components/ModalComponents';
import { Copyright } from "../components/CopyrightComponent";
import { createInterview } from "../utils/createInterview";
import { useIsAuthenticated, useMsal, useAccount, UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import { pca } from "../index"

interface StartInterviewProps extends RouteComponentProps, AuthComponentProps {

}

interface ErrorMsg {
  interviewer?: string;
}

function StartInterview({ history }: StartInterviewProps) {
  const [interviewerValid, setInterviewerValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState({} as ErrorMsg);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username, setUsername] = useState(null as string | null);
  const [email, setEmail] = useState(null as string | null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [interviewer, setInterviewer] = useState(null as string | null);
  const { accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const toHistory = function (event: React.MouseEvent) {
    event.preventDefault();
    const newRoute = "/history";
    history.push(newRoute);
  }

  const updateInterviewer = function (interviewer: string) {
    setInterviewer(interviewer);
    localStorage.setItem("interviewer", interviewer);
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const toggleErrorModal = () => {
    setIsErrorModalOpen(!isErrorModalOpen);
  }

  const login = (event: React.MouseEvent) => {
    event.preventDefault();
    pca.loginRedirect();
  }

  const logout = (event: React.MouseEvent) => {
    event.preventDefault();
    pca.logoutRedirect();
  }

  // Create whiteboard and enter interview page.
  const onInterviewerEnteredName = (event: React.FormEvent) => {
    event.preventDefault();

    // Only logged-in users with legal form input can create interview.
    if (interviewerValid) {
      console.info("interviewer is Valid")

      toggleModal();

      createInterview(interviewer)
        .then(url => {
          history.push(url); // Push home page into history. Swith to whiteboard creation page.
        })
        .catch(err => {
          console.error(err);
          toggleErrorModal();
        });
    } else {
      setErrorMsg({
        interviewer: "Please input valid value",
      });
    }
  }

  useEffect(() => {
    const validateInterviewer = (person: string | null) => {
      let interviewerValid = true;

      if (!person || person.length === 0) {
        interviewerValid = false;
        errorMsg.interviewer = 'You should input interviewer name';
      }

      setInterviewerValid(interviewerValid);
      // TODO
      setErrorMsg(errorMsg);
    };

    validateInterviewer(interviewer);
  }, [interviewer, errorMsg]);

  const isAuthenticated = useIsAuthenticated();

  // Fetch user information for logged-in user
  useEffect(() => {
    if (accounts.length === 0 && inProgress === InteractionStatus.None) {
      return;
    }

    if (isAuthenticated && accounts.length > 0 && account !== null) {
      setUsername(account.name || "");
      setEmail(account.username);
      setInterviewer(account.name || "");

      console.info("will set username in start interview", account.name);

      localStorage.setItem("username", account.name || "");
      localStorage.setItem("email", account.username);
      localStorage.setItem("interviewer", account.name || "");
    } else {
      setUsername(localStorage.getItem("username"))
      setEmail(localStorage.getItem("email"));
      setInterviewer(localStorage.getItem("interviewer"));
    }
  }, [accounts, inProgress]);

  return (
    <div className="master">
      <div className="the-strip">
        <div className="bound">
          <Navbar expand="md">
            {/* Logo */}
            <NavbarBrand href="/">
              <img src="/assets/logo2.png" width="160px" height="40px" alt="logo" />
            </NavbarBrand>

            <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} >
              <UncontrolledDropdown>
                <DropdownToggle data-toggle='dropdown' tag='span'>
                  <span color="white" className="fa fa-bars"></span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem className="font-weight-bold default-cursor" >
                    Home
                  </DropdownItem>
                  <AuthenticatedTemplate>
                    <DropdownItem className="cursor " onClick={toHistory} >
                      History
                    </DropdownItem>
                    <DropdownItem className="cursor" onClick={navigateToFeedback}>
                      Feedback
                    </DropdownItem>
                  </AuthenticatedTemplate>
                  <DropdownItem className="cursor" onClick={navigateToCareer}>Careers</DropdownItem>
                  <UnauthenticatedTemplate>
                    <DropdownItem className="cursor" onClick={login}>
                      <span className="fa fa-sign-in cursor" id="loginicon" ></span>
                      <UncontrolledTooltip placement="top" autohide={false} target="loginicon" >
                        Login
                      </UncontrolledTooltip>
                      &nbsp;&nbsp;Login
                    </DropdownItem>
                  </UnauthenticatedTemplate>

                  {/* user email & logout icon */}
                  <AuthenticatedTemplate>
                    <DropdownItem className="default-cursor">
                      {email} &nbsp;&nbsp;
                    </DropdownItem>
                    <DropdownItem className="default-cursor">
                      <span className="fa fa-sign-out cursor" id="logouticon" onClick={logout}>Log out</span>
                      <UncontrolledTooltip placement="top" autohide={false} target="logouticon" >
                        Logout
                      </UncontrolledTooltip>
                    </DropdownItem>
                  </AuthenticatedTemplate>
                </DropdownMenu>
            </UncontrolledDropdown>
            </NavbarToggler>

            <Collapse navbar>
              <Nav className="mr-auto" navbar>
                <NavItem className="ml-3 font-weight-bold default-cursor" >
                  Home
                </NavItem>
                <AuthenticatedTemplate>
                  <NavItem className="ml-3 cursor " onClick={toHistory} >
                    History
                  </NavItem>
                  <NavItem className="cursor ml-3" onClick={navigateToFeedback}>
                    Feedback
                  </NavItem>
                </AuthenticatedTemplate>
                <NavItem className="cursor ml-3" onClick={navigateToCareer}>Careers</NavItem>
              </Nav>

              <UnauthenticatedTemplate>
                <NavbarText className="cursor ml-3" onClick={login}>
                  <span className="fa fa-sign-in cursor" id="loginicon" ></span>
                  <UncontrolledTooltip placement="top" autohide={false} target="loginicon" >
                    Login
                  </UncontrolledTooltip>
                  &nbsp;&nbsp;Login
                </NavbarText>
              </UnauthenticatedTemplate>

              {/* user email & logout icon */}
              <AuthenticatedTemplate>
                <NavbarText className="ml-3 default-cursor">
                  {email} &nbsp;&nbsp;
                  <span className="fa fa-sign-out cursor" id="logouticon" onClick={logout}></span>
                  <UncontrolledTooltip placement="top" autohide={false} target="logouticon" >
                    Logout
                  </UncontrolledTooltip>
                </NavbarText>
              </AuthenticatedTemplate>

            </Collapse>
          </Navbar>
        </div>
      </div>

      <div className="bound intro user-select-none">
        <h1 className="intro-name">
          <span>OneBoard</span>
        </h1>

        <p className="intro-tag user-select-none">
          <span>Interview.</span>
          <span>Code.</span>
          <span>Get Hired.</span>
        </p>
      </div>

      <div className="bound new-bottom user-select-none">
        <h2>
          Online Interview Whiteboard
        </h2>
        <AuthenticatedTemplate>
          <Button className="btn-light text-primary" variant="" size="lg" onClick={toggleModal}>Start Interview</Button>
        </AuthenticatedTemplate>
      </div>

      <div className="container text-center home-footer">
        <Copyright />
      </div>

      <ErrorModal isOpen={isErrorModalOpen} toggle={toggleErrorModal} />

      <StartInterviewPropmptModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        onSubmit={onInterviewerEnteredName}
        username={username}
        onUsrnameChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInterviewer(e.target.value)}
        valid={interviewerValid}
        errorMsg={errorMsg}
      />
    </div>
  )
}

export default withRouter(StartInterview);
