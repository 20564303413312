import { Configuration } from "@azure/msal-browser";

const msalAuthConfig = {
  authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47/',
  clientId: 'c7a32517-5bf1-43dd-b5de-919c5e4764f7',
  redirectUri: window.location.origin + "",
  navigateToLoginRequestUrl: false,
};

export const msalConfig: Configuration = {
  auth: msalAuthConfig,
  cache: {cacheLocation: "localStorage"}
};
