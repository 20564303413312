import "./App.scss";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";

import StartInterview from "./containers/StartInterviewContainer";
import CodeView from "./containers/CodeviewContainer";
import FinishView from "./containers/FinishContainer";
import NotFound from "./containers/NotFoundContainer";
import History from "./containers/HistoryContainer";

import {useAuth} from "./utils/withAuth";

function App() {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Switch>
          <Route path="/interview/:url" component={CodeView} />
          <Route path="/home" component={StartInterview} />
          <Route path="/finish" component={FinishView}></Route>
          <Route path="/404" component={NotFound} />
          <Route path="/history" component={useAuth(History)} />
          <Route path="/" component={StartInterview} />
          <Redirect to="/home"></Redirect>
        </Switch>
      </BrowserRouter>

    </React.Suspense>
  );
}

export default App;
