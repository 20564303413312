import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, PopoverBody, PopoverHeader, Row, UncontrolledPopover, UncontrolledTooltip } from "reactstrap";

interface TimerProps {
    setAlarmOut: (tag: boolean) => void;
}

export default function Timer({ setAlarmOut }: TimerProps) {
    const [timer, setTimer] = useState(false);
    const [counter, setCounter] = useState([0, 30, 0]); // actual time counter
    const countRef = useRef(counter);
    countRef.current = counter;

    // refresh timer
    useEffect(() => {
        let interval: any; //!!
        if (timer) {
            interval = setInterval(() => {
                let currCount = countRef.current;
                let hour = currCount[0];
                let minute = currCount[1];
                let second = currCount[2];
                if (second > 0) {
                    setCounter([hour, minute, second - 1]);
                } else if (minute > 0) {
                    setCounter([hour, minute - 1, second + 59]);
                } else {
                    // time out!
                    setAlarmOut(true);
                    setTimer(!timer);
                }
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const manageTimer = () => {
        setTimer(!timer);
    }

    const resetTimer = () => {
        setTimer(false);
        setCounter([0, 30, 0]);
    }

    const padding = (num: number, length: number) => {
        return (Array(length).join("0") + num).slice(-length);
    }

    // convert time count to pattern 00:00:00
    const convert = () => {
        let hour = padding(counter[0], 2);
        let minute = padding(counter[1], 2);
        let second = padding(counter[2], 2);
        return `${hour} : ${minute} : ${second}`
    }

    // index, 0:hour, 1:minute, 2:second
    const handleCounterChange = (index: number, e: any) => {
        // timeInput[index] = e.target.value;
        // let newCounter = counter;
        // newCounter[index] = e.target.value;
        let value = e.target.value;
        if (index === 1 && value > 24) {
            value = 24;
        }
        if (index > 1 && value > 60) {
            value = 60;
        }
        counter[index] = value;
        setCounter(counter)
    };

    return (
        <div className="justify-content-center" style={{textAlign:"center"}}>
            <svg id="PopoverClock" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-alarm-fill" viewBox="0 0 16 16" style={{ marginBottom: "3px" }}>
                <path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5zm2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527z" />
            </svg>
            <UncontrolledTooltip
                placement="top"
                target="PopoverClock"
            >
                Timer!
            </UncontrolledTooltip>
            &nbsp;&nbsp;
            <UncontrolledPopover
                placement="bottom"
                target="PopoverClock"
                trigger="legacy"
            >
                <PopoverHeader>
                    Stopwatch
                </PopoverHeader>
                <PopoverBody>
                    <Container>
                        <Row>
                            <Col className="bg-light" sm="12" xs="6">
                                <Form inline>
                                    <FormGroup row>
                                        <Label for="hourInput">
                                            Duration:
                                        </Label>
                                        <Input id="hourInput" placeholder="0" type="text" style={{ width: "45px" }}
                                            // value={counter[0]}
                                            onChange={e => handleCounterChange(0, e)}
                                        />
                                        &nbsp;:&nbsp;
                                        <Input id="minuteInput" placeholder="30" type="text" style={{ width: "45px" }}
                                            // value={counter[1]}
                                            onChange={e => handleCounterChange(1, e)}
                                        />
                                        &nbsp;:&nbsp;
                                        <Input id="secondInput" placeholder="0" type="text" style={{ width: "45px" }}
                                            // value={counter[2]}
                                            onChange={e => handleCounterChange(2, e)}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs="6">
                                <Button className="btn-light text-primary"
                                    onClick={() => manageTimer()}>
                                    {timer ? 'Pause' : 'Start'}
                                </Button>
                                &nbsp;
                            </Col>
                            <Col xs="6">
                                <Button className="btn-light text-primary"
                                    variant='info'
                                    onClick={() => resetTimer()}>
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </PopoverBody>
            </UncontrolledPopover>
            <span className="text-center" style={{marginTop:"3px", verticalAlign:"middle" }}>{convert()}</span>
        </div>
    );
}
