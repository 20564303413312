import React, { useState } from "react";
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import InterviewCard from './InterviewCardComponent';

interface PagesProps {
  dataSet: any;
  deleteData: any;
}

/*
    分页显示面试记录
     - props: dataset [面试条目List]
*/
export default function Pages({ dataSet, deleteData}: PagesProps) {
  const pageSize = 5;
  const pageCount = Math.ceil(dataSet.length / pageSize);
  const [currentPage, setCurrentPage] = useState(0);

  const handleClick = function (e: any, index: number) {
    e.preventDefault();
    setCurrentPage(index);
  }

  const renderPagination =(currentPage: number) =>{
    const paginationList: number[]=[];
    for(var i=-2; i<=2; i++){
      if(currentPage+i>=0 && currentPage+i<pageCount) paginationList.push(currentPage+i);
    }
    return paginationList;
  }
  return (
    <div className="history-cards" >
      <div>
        {/* Interviews */}
        {
          dataSet.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          ).map((data: any, i: number) =>
            <div className="history-item" key={i}>
              <p />
              <InterviewCard complete={data.completed} interviewee={data.interviewee} time={data.createTime} url={data.completed === true ? data.permParam : data.urlParam} participates={data.participates} deleteData={deleteData}/>
              <p />
            </div>
          )
        }
      </div>

      {/* Pagination item */}
      <div className="pagination-wrapper">
        <Pagination aria-label="Page navigation">
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink onClick={(e: any) => handleClick(e, 0)} href="#" first />
          </PaginationItem>
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink onClick={(e: any) => handleClick(e, currentPage - 1)} previous href="#" />
          </PaginationItem>
          {renderPagination(currentPage).map((data:number, i: number) =>
            <PaginationItem active={data === currentPage} key={data}>
              <PaginationLink onClick={(e: any) => handleClick(e, data)} href="#">
                {data + 1}
              </PaginationLink>
            </PaginationItem>
          )}

          <PaginationItem disabled={currentPage >= pageCount - 1}>
            <PaginationLink onClick={(e: any) => handleClick(e, currentPage + 1)} next href="#" />
          </PaginationItem>
          <PaginationItem disabled={currentPage >= pageCount - 1}>
            <PaginationLink onClick={(e: any) => handleClick(e, pageCount-1)} href="#" last />
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  )
}

