export function loadThemes() {
  // 深色主题
  require('codemirror/theme/night.css');
  require('codemirror/theme/darcula.css');
  require('codemirror/theme/material.css');
  require('codemirror/theme/material-ocean.css');

  // 浅色主题
  require('codemirror/theme/yeti.css');
  require('codemirror/theme/eclipse.css');
  require('codemirror/theme/mdn-like.css');
  require('codemirror/theme/paraiso-light.css');
}

export function loadHighlights() {
  require("codemirror/mode/javascript/javascript");
  require("codemirror/mode/clike/clike");
  require("codemirror/mode/python/python");
  require("codemirror/mode/go/go");
  require("codemirror/mode/swift/swift");
  require("codemirror/mode/rust/rust");
}

export const defaultTheme = "default";

export const defaultMode = "plaintext";
