import React from "react";

interface ValidationMessageProps {
  valid: boolean;
  message: string;
}

export default function ValidationMessage({ valid, message }: ValidationMessageProps) {
  return (
    valid ? null :
    <div className='text-danger'><i className="fa fa-danger"></i>{message}</div>
  );
}
