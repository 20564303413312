import React, { useEffect, useState } from "react";
import { Card, CardText, CardBody, CardTitle, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row } from "reactstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import InterviewStatus from "./InterviewStatusComponent";
import moment from "moment";
import { deleteInterview, getToken } from "../services/boardService";
import { DeleteButton } from "./DeleteButtonComponent";
import { isEnableDeleteInterviewEnabled } from "../config";

export interface InterviewCardProps extends RouteComponentProps {
  url: string;
  time: any;
  participates: Array<any>;
  complete: any;
  interviewee: any;
  deleteData: any;
}

/*
    显示一条面试内容的Card
    Check one interview content
     - props: complete [whether complete 是否完成], interviewee [interviewee name 面试者姓名] , time [interview time 面试时间]
*/
function InterviewCard({ url, history, time, participates, complete, deleteData }: InterviewCardProps) {
  const handleClick = function (event: React.MouseEvent<HTMLHeadingElement>) {
    event.preventDefault();

    getToken(url, complete ?? false)
        .then((res) => {
          const token = res.data;
          const new_route = `/interview/${url}?token=${token}`;
          history.push(new_route); // Switch to interview whiteboard page 界面切换到创建白板的界面
        })
        .catch((err) => {
          console.error(err);
        });
  }

  const createTime = moment.utc(time).local().format("MM/DD/YYYY, HH:mm:ss");

  const persons = participates.join(", ") || "(no participant)";

  // TODO: move delete button to new component
  const [deleteModal, setDeleteModal] = useState(false);

  const toggle = () => setDeleteModal(!deleteModal);

  const deleteRec = () => {
    toggle();

    deleteInterview(url, complete)
      .then((res) => {
        console.info(res.data);
      })
      .catch((err) => {
        console.error(err)
      }
      );

    deleteData(function (prev: any) {
      return prev.filter((item: any) => {
        if (complete) {
          return item.permParam !== url;
        }
        return item.urlParam !== url;
      });
    });
  };

  return (
    <div className="interview-card">
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h5 style={{ flexBasis: "13rem" }} onClick={handleClick} className="card-cursor cursor">{createTime} &nbsp; </h5>
                  <InterviewStatus complete={complete} />
                </div>
              </CardTitle>
            </Col>
            {
              isEnableDeleteInterviewEnabled ?
                <DeleteButton
                  toggle={toggle}
                  isOpen={deleteModal}
                  deleteHandler={deleteRec}
                />
                : null
            }
          </Row>
          {
            <CardText>
              <span className="fa fa-user" id="interviewee_name" /><span className="text-dark">&nbsp; {participates.length >= 2 ? "Participants" : "Participant"}: {persons}</span>
            </CardText>
          }
        </CardBody>
      </Card>
    </div>
  )
}

export default withRouter(InterviewCard);
